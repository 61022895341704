export const LANGUAGES = (trans) =>[
  {
    label: trans("global.language.en"),
    value: "EN",
    flag: "flag-us.png",
  },
  {
    label: trans("global.language.de"),
    value: "DE",
    flag: "flag-de.png",
  },
  {
    label: trans("global.language.es"),
    value: "ES",
    flag: "flag-es.png",
  },
];

export const BACKEND_PRICE_DECIMAL_POINTS = 4;
export const ALLOWED_DEVIATION = 0.03;
export const DEFAULT_BUDGET_MAPPING = {
  C: "Company",
  D: "Department",
  U: "Personal",
};
export const BASIC_ROLE = "B";
export const USER_ROLES_MAPPING = {
  B: "Basic",
  BU: "Buyer",
  MD: "Managing Director",
  AC: "Accountant",
  O: "Admin",
  AP: "Approver",
  CO: "Contract Owner",
  CR: "Controller",
  NA: "News Admin",
  AD: "Auditor",
};

export const USER_ROLES_HELP = {
  BU: "Help text for Buyer",
  MD: "Help text for Managing Director",
  AC: "Help text for Accountant",
  O: "Help text for Admin",
  AP: "Helpt text for Approver",
  NA: "Help text for News Admin",
};

export const DATEV_STATUS = {
  INITIALIZED: 0,
  JOB_CREATED: 5,
  FILES_UPLOADED: 10,
  JOB_SUCCESSFUL: 15,
  JOB_PARTIALLY_SUCCESSFUL: 20,
  JOB_FAILED: 25,
  UNKNOWN_ERROR: 40,
  INTERNAL_ERROR: 45,
};

export const WORKFLOW_ROLES_MAPPING = {
  BU: "Buyer",
  MD: "Managing Director",
  AC: "Accountant",
  O: "Admin",
  AP: "Approver",
  RQ: "Original Requester",
  CR: "Controller",
};

export const USER_BADGE_STYLE = {
  O: "bg-hivebuy-green",
  MD: "bg-hivebuy-red",
  AP: "bg-gray-400",
  AC: "bg-blue-400",
  BU: "bg-hivebuy-yellow text-hivebuy-grey",
  CO: "bg-hivebuy-lilac text-gray-600",
  B: "bg-gray-400",
  CR: "bg-purple-600",
  NA: "bg-amber-500",
  AD: "bg-hivebuy-darkgreen",
};

export const USER_AVATAR_STYLE = {
  0: "bg-primary",
  1: "bg-purple-500",
  2: "bg-indigo-500",
  3: "bg-pink-500",
  4: "bg-yellow-500",
  5: "bg-blue-500",
};

export const CURRENCY_LOCALE = {
  EUR: "de",
  USD: "en",
  GBP: "en-EN",
  SEK: "sv-SE",
  PLN: "pl-PL",
  CHF: "de-CH",
};

export const CURRENCY_TO_LOCALE = {
  EUR: "de-DE",
  USD: "en-US",
  GBP: "en-EN",
  SEK: "sv-SE",
  PLN: "pl-PL",
  CHF: "de-CH",
};

export const LOADER_COLOR = "#9E4770";

export const PR_PRODUCT_UNITS = ["pc", "days", "hours"];

export const VAT_RATES = [
  {
    name: "19%",
    value: 19,
  },
  {
    name: "7%",
    value: 7,
  },
  {
    name: "0%",
    value: 0,
  },
];

export const NET_GROSS = (trans) => {
  const values = [
    {
      name: trans("global.pricing.gross"),
      value: "gross",
    },
    {
      name: trans("global.pricing.net"),
      value: "net",
    },
  ];
  return values;
};

export const CURRENCY = [
  {
    name: "EUR",
    value: "EUR",
    symbol: "€",
    vatRates: [
      {
        name: "19%",
        value: 19,
      },
      {
        name: "7%",
        value: 7,
      },
      {
        name: "0%",
        value: 0,
      },
    ],
  },
  {
    name: "USD",
    value: "USD",
    symbol: "$",
    vatRates: [
      {
        name: "0%",
        value: 0,
      },
    ],
  },
  {
    name: "GBP",
    value: "GBP",
    symbol: "£",
    vatRates: [
      {
        name: "20%",
        value: 20,
      },
      {
        name: "5%",
        value: 5,
      },
      {
        name: "0%",
        value: 0,
      },
    ],
  },
  {
    name: "PLN",
    value: "PLN",
    symbol: "zł",
    vatRates: [
      {
        name: "23%",
        value: 23,
      },
      {
        name: "8%",
        value: 8,
      },
      {
        name: "5%",
        value: 5,
      },
      {
        name: "0%",
        value: 0,
      },
    ],
  },
  {
    name: "SEK",
    value: "SEK",
    symbol: "kr",
    vatRates: [
      {
        name: "25%",
        value: 25,
      },
      {
        name: "12%",
        value: 12,
      },
      {
        name: "4%",
        value: 4,
      },
      {
        name: "0%",
        value: 0,
      },
    ],
  },
  {
    name: "CHF",
    value: "CHF",
    symbol: "CHf",
    vatRates: [
      {
        name: "7,7%",
        value: 7.7,
      },
      {
        name: "3,7%",
        value: 3.7,
      },
      {
        name: "2,5%",
        value: 2.5,
      },
      {
        name: "0%",
        value: 0,
      },
    ],
  },
];

export const PURCHASE_REQUEST_STATUS_MAPPING = {
  P: "pending",
  A: "approved",
  R: "rejected",
  C: "cancelled",
  D: "delegated",
  CA: "cancel",
  RE: "restore",
  DR: "Change Request",
};

export const PURCHASE_REQUEST_STATUSES_STYLES = {
  A: "bg-hivebuy-green",
  R: "bg-hivebuy-red",
  P: "bg-hivebuy-yellow",
  C: "bg-hivebuy-grey",
};

export const APPROVAL_STATUS_MAPPING = {
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  DELEGATED: "Delegated",
  REVISION: "Revision",
};

export const CATALOGUE_ITEM_SOURCE = {
  SUPPLIER: "companysupplier",
  CATALOGUE_ITEM: "catalogueitem",
  CONTRACT: "contract",
};

export const PURCHASE_ORDER_STATUSES = {
  A: "Ordered",
  O: "Ordered",
  R: "Rejected",
  P: "Pending",
  PA: "Partial Delivered",
  RS: "Resolved",
  S: "Order Sent",
  CA: "Canceled",
  D: "Delivered",
  CO: "Complete",
};

export const PURCHASE_ORDER_STATUS_MAPPING = {
  ORDERED: "O",
  CANCELED: "CA",
  DELIVERED: "D",
  COMPLETE: "CO",
  SENT: "S",
  REJECTED: "R",
  PENDING: "P",
  INVOICED: "I",
  DELIVERY: "ID",
  "PARTIAL DELIVERED": "PA",
  RESOLVED: "RS",
  ON_HOLD: "OH",
};
export const PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING = {
  ORDERED: "O",
  DELIVERED: "D",
  COMPLETE: "CO",
  CANCELLED: "CA",
  REJECTED: "R",
  SENT: "S",
  PENDING: "P",
  INVOICED: "I",
  DELIVERY: "ID",
  "PARTIAL DELIVERED": "PA",
  RESOLVED: "RS",
};

export const PURCHASE_REQUEST_ITEM_STATUSES = {
  A: "Approved",
  R: "Rejected",
  P: "Pending",
  O: "Ordered",
};

export const PURCHASE_REQUEST_STATUSES = {
  APPROVED: "A",
  REJECTED: "R",
  PENDING: "P",
  Cancelled: "C",
};

export const PURCHASE_ORDER_STATUSES_STYLES = {
  A: "bg-hivebuy-green",
  O: "bg-hivebuy-green",
  R: "bg-hivebuy-red",
  P: "bg-hivebuy-yellow",
  S: "bg-hivebuy-yellow",
  PA: "bg-hivebuy-green",
  RS: "bg-hivebuy-green",
  CA: "bg-gray-400",
  D: "bg-hivebuy-green",
  CO: "bg-hivebuy-green",
  I: "bg-hivebuy-green",
  ID: "bg-hivebuy-darkgreen",
  OH: "bg-hivebuy-yellow",
};

export const MAX_FILES_UPLOADS = 3;
export const IMAGE_ACCEPT_TYPES = ".png, .jpg, .jpeg";
export const FILE_ACCEPT_TYPES = ".pdf, .png, .jpg, .jpeg";
export const CATALOGUE_ALLOWED_ROLES = ["O", "MD", "CR", "BU"];
export const CSV_FILE_TYPES = [".xlsx", ".csv"];
export const AUTH_LOGOUT_URL = "https://auth.hivebuy.com/oauth2/logout";

export const PROPOSAL_STATUSES_MAPPING = {
  open: "OP",
  draft: "DR",
  completed: "CO",
  closed: "CL",
};

export const BUDGET_DISTRIBUTION_OPTIONS = ["Percentage (evenly)", "Custom"];
export const TIMELINE_STATUS = {
  approval: "approval",
  open: "open",
  fullyApproved: "fullyApproved",
  ordered: "ordered",
  sent: "sent",
  resent: "resent",
  delivered: "delivered",
  rejected: "rejected",
  cancelled: "cancelled",
  pending: "pending",
  revision: "revision",
  instantApproved: "instantApproved",
  inDelivery: "inDelivery",
  invoiced: "invoiced",
  complete: "complete",
  delegated: "Delegated",
  partialDelivered: "partialDelivered",
};

export const ALLOWED_API_ON_ROUTE = (route) => {
  const apiRoutes = {
    ApiKeyList: ["users"],
    NewShoppingList: [
      "projects",
      "companyAddresses",
      "users",
      "legalTendencies",
    ],
    ShoppingList: ["projects", "companyAddresses", "users"],
    Dashboard: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
    ],
    NotAllowed: [],
    notifications: [],
    PurchaseRequest: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "projects",
      "companyAddresses",
      "users",
      "paymentMethods",
      "legalTendencies",
      "customFields",
    ],
    CreatePurchaseRequest: [
      "categories",
      "companySuppliers",
      "projects",
      "companyAddresses",
      "users",
      "paymentMethods",
      "legalTendencies",
      "customFields",
      "companyIntegratedSuppliers",
    ],
    PurchaseRequestEdit: [
      "categories",
      "companySuppliers",
      "projects",
      "companyAddresses",
      "users",
      "paymentMethods",
      "legalTendencies",
      "customFields",
      "companyIntegratedSuppliers",
    ],
    EditPurchaseRequest: [
      "companyAddresses",
      "paymentMethods",
      "categories",
      "companySuppliers",
      "users",
      "customFields",
    ],
    Accounting: [
      "users",
      "projects",
      "categories",
      "integrations",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "legalTendencies",
    ],
    Catalogue: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "companyAddresses",
      "users",
      "projects",
      "paymentMethods",
      "legalTendencies",
      "customFields",
    ],
    Proposals: ["categories", "companySuppliers", "users"],
    ProposalBoard: [
      "categories",
      "companySuppliers",
      "users",
      "companyAddresses",
      "paymentMethods",
      "projects",
      "legalTendencies",
    ],
    UserProfile: ["categories", "companySuppliers", "users"],
    EditProfile: [
      "categories",
      "companySuppliers",
      "users",
      "companyAddresses",
      "paymentMethods",
    ],
    Projects: ["categories", "companySuppliers", "users"],
    ProjectView: ["users", "departments", "legalTendencies"],
    DraftList: ["categories", "companySuppliers"],
    Requests: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
      "legalTendencies",
    ],
    RequestDetail: [
      "companyAddresses",
      "paymentMethods",
      "legalTendencies",
      "customFields",
    ],
    Suppliers: ["categories", "companyIntegratedSuppliers", "users"],
    NewRequest: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
      "companyAddresses",
      "paymentMethods",
      "legalTendencies",
      "customFields",
    ],
    Analytics: ["categories", "companySuppliers", "users"],
    DepartmentBudgetOverview: ["categories", "companySuppliers", "users"],
    BasicAnalytics: ["categories", "companySuppliers", "users"],
    DetailedAnalytics: ["categories", "companySuppliers", "users"],
    PurchaseOrders: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
      "projects",
      "integrations",
    ],
    InvoiceDashboard: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
      "legalTendencies",
      "projects",
      "taxCodes",
    ],
    InvoiceDecision: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
    ],
    CompanyManagement: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
    ],
    ConnectedSuppliers: ["categories", "companyIntegratedSuppliers", "users"],
    Settings: ["categories", "companySuppliers", "users"],
    Users: ["categories", "companySuppliers", "users", "legalTendencies"],
    Departments: ["users", "legalTendencies"],
    Budgets: ["categories", "companySuppliers", "users", "legalTendencies"],
    Categories: ["categories", "companySuppliers", "users"],
    CompanySuppliers: [
      "users",
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "globalPolicies",
    ],
    Integrations: [
      "categories",
      "companySuppliers",
      "users",
      "companyIntegratedSuppliers",
      "integrationsSlugs",
    ],
    Webhooks: [
      "categories",
      "companySuppliers",
      "companyIntegratedSuppliers",
      "users",
      "integrations",
    ],
    "Approval Workflow": [
      "categories",
      "users",
      "legalTendencies",
      "companySuppliers",
      "companyIntegratedSuppliers",
    ],
    "Approval Workflow Editor": [
      "categories",
      "users",
      "legalTendencies",
      "companySuppliers",
      "companyIntegratedSuppliers",
    ],
    NewContract: ["users", "categories", "companySuppliers"],
    ViewContract: [
      "users",
      "categories",
      "companySuppliers",
      "catalogues",
      "categories",
      "users",
    ],
    EditContract: ["users", "departments", "categories", "companySuppliers"],
    LegalTendencies: [
      "companyAddresses",
      "paymentMethods",
      "users",
      "categories",
    ],
    News: ["users", "legalTendencies"],
  }[route];
  return apiRoutes ? [...apiRoutes, "departments"] : [];
};

export const CONTRACT_TYPES = {
  Unit: "U",
  Supplier: "S",
  HR: "HR",
};

export const COST_RECURRENCE_TYPES = {
  open: "O",
  monthly: "M",
  yearly: "Y",
  servicePeriod: "SP",
};

export const CONTRACT_STATUS_TYPES = {
  pending: "P",
  approved: "A",
  rejected: "R",
  active: "AC",
  inactive: "IA",
};

export const PRICE_FIELDS = [
  "grossAmount",
  "netAmount",
  "vat",
  "grossAmountCurrency",
  "netAmountCurrency",
];

export const SHOPPING_LIST_STATUSES = {
  open: "O",
  done: "C",
};

export const PAGINATION_PAGE_SIZE = 100;
export const IDLE_ATTRIBUTES = {
  reminders: process.env.VUE_APP_IDLE_REMINDERS.split(",").map(Number),
  wait: process.env.VUE_APP_IDLE_WAIT,
  duration: process.env.VUE_APP_IDLE_DURATION,
};
export const SUPPLIER_FILTER_KEYS = {
  category: "child_parent_category__in",
};

export const FILE_OPTIONS_CLASS_MAPPING = (source) => {
  return {
    order: {
      proforma_invoice: "bg-hivebuy-green",
      offer: "bg-purple-600",
      other: "bg-gray-400",
      delivery_note: "bg-hivebuy-darkgreen",
    },
    request: {
      other: "bg-hivebuy-green",
      order: "bg-blue-400",
    },
  }[source];
};

export const NEWS_SEVERITY_OPTIONS_CLASS_MAPPING = {
  information: "bg-hivebuy-green",
  critical: "bg-red-600",
  normal: "bg-blue-400",
};
export const REQUEST_SEARCH_NON_ALLOWED_FIELDS = [
  "id",
  "requestedBy",
  "status",
  "requestStatus",
  "purchaseOrder",
  "budgetLevel",
  "budget",
  "budgetPlanningEnabled",
  "decidedAt",
  "companyAddress",
  "paymentMethod",
  "approvals",
  "requestedAt",
  "actionRequired",
  "result",
  "position",
  "isInBudget",
  "itemStatuses",
  "itemPrices",
  "needByDate",
  "invoiceAddress",
  "autoProcessing",
  "shoppingItems",
  "shippingDetail",
  "instantApproval",
  "wasInBudget",
  "requestedFor",
];
export const CURRENCY_OPTIONS = [
  { label: "EUR", value: "EUR", flag: "flag-eu.png", locale: "de-DE" },
  { label: "USD", value: "USD", flag: "flag-us.png", locale: "en-US" },
  { label: "GBP", value: "GBP", flag: "flag-uk.png" },
  { label: "SEK", value: "SEK", flag: "flag-se.png" },
];

export const REQUESTED_FOR_TYPES = {
  MY_SELF: "myself",
  USER: "user",
  DEPARTMENT: "department",
  PROJECT: "project",
};
export const INVOICE_VALIDATE_FIELD_MAPPING = (field) => {
  return { id: "invoiceId" }[field] || field;
};
