<template>
  <div id="supplier">
    <div v-if="!suppliersLoaded" class="flex items-center">
      <HivebuyLoader class="h-40" />
    </div>
    <div v-else>
      <div>
        <h3 class="page-title">
          {{ $t("companyManagement.connectedSupplier.title") }}
        </h3>
      </div>

      <ul role="list">
        <li v-for="supplier in integratedSuppliersList()" :key="supplier.id">
          <Supplier :supplier="supplier.supplier" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Supplier from "@/components/CompanyManagement/ConnectedSuppliers/Supplier";
import { HivebuyLoader } from "@/components/shared/index";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_INTEGRATED_SUPPLIERS,
} from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";

export default {
  components: {
    Supplier,
    HivebuyLoader,
  },
  data() {
    return {
      loading: false,
      integratedSupplierList: [],
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["integratedSuppliersList"]),
    suppliersLoaded() {
      return !this.isApiLoading("companyIntegratedSuppliers");
    },
  },

  mounted() {
    this.getIntegratedSuppliers();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getIntegratedSuppliers: GET_INTEGRATED_SUPPLIERS,
    }),
  },
};
</script>
