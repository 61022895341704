// export const BASE_URL = "http://localhost:8000/api/";
export const BASE_URL = process.env.VUE_APP_BACKEND_API;
export const USER_LOGOUT_URL = "logout/";
export const HUBSPOT_URL = "hubspot-create-token/";
// Users URLS
export const USERS_AUTH_API_URL = "users/";
//Company
export const COMPANY_API_URL = "company/company/";
export const USERS_API_URL = "company/user/";
export const ADDRESSES_API_URL = "company/address/";
export const CATEGORIES_API_URL = "company/category/";
export const INVITE_API_URL = "company/invite/";
export const USER_INVITE_URL = "invite/";
export const DEPARTMENTS_API_URL = "company/department/";
export const COMPANY_SUPPLIERS_API_URL = "supplier-portal/company-suppliers/";
export const INTEGRATED_SUPPLIERS_URL = "supplier-portal/suppliers/";
export const PURCHASE_REQUEST_API_URL = "company/purchase-request/";
export const ACTIONABLE_PURCHASE_REQUEST_API_URL =
  "company/my-purchase-request-action/";
export const MY_PURCHASE_REQUEST_URL = "company/my-purchase-request/";

export const PURCHASE_REQUEST_PREVIEW_API_URL =
  "company/purchase-request-preview/";
export const WORKFLOW_API_URL = "company/approval-flow/";
export const GLOBAL_POLICY_API_URL = "company/global-policies/";
export const PAYMENT_METHODS_API_URL = "company/payment-method/";
export const INTEGRATIONS_API_URL = "integrations/";
export const INTEGRATION_API_URL = "integration/";
export const INTEGRATIONS_SLUGS_API_URL = "integrations/integration-slugs/";
export const DATEV_INTEGRATION_API_URL = "/integration/datev/";
export const SEPA_INTEGRATION_API_URL = "company/invoices/sepa-xml/";
export const DELETE_SLACK = "/integration/delete-slack";
export const BUDGET_API_URL = "company/budget/";
export const SLACK_URL = (user_id) => {
  return `https://slack.com/oauth?client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&scope=incoming-webhook+chat%3Awrite%3Abot+channels%3Awrite+chat%3Awrite%3Auser+groups%3Awrite+im%3Awrite+mpim%3Awrite+bot&user_scope=&redirect_uri=${BASE_URL}redirect%2Fslack&state=${user_id}&granular_bot_scope=0&single_channel=0&install_redirect=&tracked=1&team=`;
};
export const ANALYTICS_API_URL = "/analytics";

export const PR_ANALYTICS_URL = "/analytics/purchase-request";
export const DEPT_ANALYTICS_URL = "/analytics/department";
export const DEPT_ANALYTICS_BUDGET_URL = "/analytics/department-budget";
export const PURCHASE_REQUEST_ITEM_API_URL = "company/purchase-request-item/";
export const PURCHASE_REQUEST_ITEM_STATUS_UPDATE_API_URL =
  "company/purchase-request-item/update-status/";
export const INVOICE_STATUS_UPDATE = (
  id,
  isExternal = false,
  action = "Approved"
) => {
  const entity = isExternal ? "invoices" : "invoice-purchase-orders";
  if (action == "Rejected") {
    return `company/invoices/${id}/mark-as-rejected/`;
  }

  return `company/${entity}/${id}/update-status/`;
};
export const INVOICE_VALIDATE = "company/invoices/validates/";
export const INVOICE_DELETE = (invoiceId, poId) =>
  `company/invoices/${invoiceId}/detach/${poId}/`;
export const RESEND_INVITE_URL = "company/invite/";
export const INVOICE_PURCHASE_ORDERS = "company/invoice-purchase-orders/";
export const ACTION_REQUIRED_INVOICE_URL = "company/my-invoice-actions/";
export const CONTRACT_API_URL = "company/contracts/";
export const LEGAL_TENDENCY_API_URL = "company/legal-entity/";

//external
export const LINK_PREVIEW_URL = process.env.VUE_APP_LINK_PREVIEW_URL;
export const LINK_PREVIEW_KEY = process.env.VUE_APP_LINK_PREVIEW_KEY;
export const CATALOGUE_ITEMS_URL = "supplier-portal/items/";
export const CATALOGUES_API_URL = "supplier-portal/catalogues/";
export const PURCHASE_ORDER_API_URL = "company/purchase-order/";
export const PURCHASE_ORDER_STATUS_URL = "company/purchase-order-status/";
export const INVOICE_PURCHASE_ORDER_URL = "company/invoice-purchase-orders/";
export const PURCHASE_ORDER_DOWNLOAD_URL =
  "company/purchase-order/purchase-order-report/";
export const PURCHASE_ORDER_INVOICES_URL = "company/invoices/invoices-zip/";
export const SUPPLIERS_URL = "supplier-portal/suppliers/";
export const SOCKET_URL = process.env.VUE_APP_SOCKET_URL;
export const DASHBOARD_PR_URL = "company/dashboard-purchase-request/";
export const INVOICES_API_URL = "/company/invoices/";
export const PROPOSALS_API_URL = "company/request-for-proposal-board/";
export const PROPOSAL_SUGGESTIONS_API_URL = "company/rfp-with-feedback/";
export const FEATURE_FLAG_URL = "/company/feature-flag-status/";
export const EXPORT_DATA_URL = "core/etl/";
export const PROJECT_API_URL = "company/project/";
export const ORDER_ITEM_STATUS = "company/order-item-status/";
export const INVOICE_OCR = "ocr";
export const QUICK_LINKS = "company/supplier-catalogue/";
export const INVOICE_OCR_PREVIEW = "ocr-preview";
export const SHOPPING_LIST_API_URL = "company/shopping-list/";
export const RESTORE_PR_ITEM = (id) =>
  `company/purchase-request-item/${id}/restore/`;
export const SEARCH_ITEMS = "company/purchase-request-item/items-search/";
export const CHANGE_SHOPPING_LIST_RELATION_URL =
  "company/pur-shopping-list/change-item/";
export const DOWNLOAD_URL = "company/download/";
export const DOWNLOAD_FILES = "company/download/downloads-zip/";
export const DELIVERY_NOTE_URL =
  "company/purchase-order/download-delivery-notes/";
export const COST_SAVING_URL = "company/cost-savings/";
export const COMPANY_COST_SAVING_URL = "company/company-cost-savings/";
export const SEARCH_URL = "core/search";
export const ACCRUAL_REPORT_URL = "company/accrual/accural-csv/";
export const MARK_PO_PENDING =
  "company/invoice-purchase-orders/mark-as-pending/";
export const MARK_INVOICE_PENDING = (id) =>
  `company/invoices/${id}/mark-as-pending/`;
export const MARK_NOT_INVOICE = (id) =>
  `/company/invoices/${id}/mark-as-not-invoice/`;
export const UN_ASSIGN_PURCHASE_ORDER = (id) =>
  `company/invoice-purchase-orders/${id}/unassign/`;
export const API_KEYS_URL = "company/api-key/";
export const CUSTOM_FIELD_URL = "company/custom-fields/";
export const COMPANY_DEBTORS_URL = "company/company-debtors/";

export const NEWS_URL = "company/news/";
export const USER_NEWS_URL = "company/user-news/";
export const ACTIVITY_HISTORY = "core/history";
export const EXCHANGE_RATE = "core/exchange-rates/";

export const VALIDATION_API = "company/purchase-request-checkout/";
export const NEW_REQUEST_CREATION_API = "company/purchase-request-generate/";
export const DRAFT_PR_URL = "company/draft-purchase-request/";

export const SUPPLIER_ORDERS_API_URL = "supplier-portal/supplier-orders/";
export const SIGN_UP = "signup/";

export const TAX_CODES_API_URL = "company/tax-codes/";
