<template>
  <div class="header">
    <div class="header-left">
      <p v-if="invoice.title" class="text-gray-600 font-normal">
        {{ $t("global.title") }}:
        <span class="text-primary font-semibold ml-1">
          {{ invoice.title }}
        </span>
      </p>
      <p class="text-gray-600 font-normal hidden sm:inline-flex">
        {{ $t("global.invoiceNumber") }}:
        <span class="text-primary font-semibold ml-2">
          {{ invoice.invoiceNumber }}
        </span>
      </p>

      <div
        v-if="invoice.invoicePurchaseOrders?.length"
        class="text-gray-600 text-md font-normal flex items-center max-w-full"
      >
        <span class="mr-2"> {{ $t("global.purchaseRequestShort") }}: </span>
        <Navigation
          :invoice-purchase-orders="invoice.invoicePurchaseOrders"
          :select-po="selectPo"
          :selected-po="invoicePurchaseOrder"
        />
      </div>

      <span v-if="!isObjectEmpty(invoicePurchaseOrder)">
        <p class="text-gray-600 font-normal hidden sm:block">
          {{ $t("global.purchaseRequestShort") }}
          {{ $t("global.decisions.created") }} /
          {{ $t("global.decisions.approved") }}:
          <span class="text-primary font-semibold ml-2">
            {{ formattedDate(purchaseRequest.requestedAt) }} /
            {{ formattedDate(purchaseRequest.decidedAt) }}
          </span>
        </p>
        <p class="text-gray-600 font-normal hidden sm:flex sm:items-center">
          {{ $t("global.supplier") }}:
          <span class="text-primary font-semibold ml-2">
            <SupplierName :purchase-request="purchaseRequest" squashed />
          </span>
        </p>
        <p class="text-gray-600 font-normal hidden sm:flex sm:items-center">
          {{ $t("global.category") }}:
          <span class="text-primary font-semibold ml-2">
            <Category :purchase-request="purchaseRequest" squashed />
          </span>
        </p>
      </span>
    </div>
    <div class="header-right">
      <div
        v-if="invoiceActionRequired || isAllowedRole"
        class="flex flex-row-reverse"
      >
        <div class="buttons-holder">
          <Button
            v-if="invoiceActionRequired || isAllowedRole"
            id="forward-button"
            size-class="sm"
            :loading="loading"
            :on-click-handler="toggleForwardModal"
          >
            <template #icon>
              <FastForwardIcon />
            </template>
            {{ $t("global.decisions.forward") }}
          </Button>
          <Button
            v-if="isAllowedRole && isObjectStatus('RV')"
            id="approve-button"
            size-class="sm"
            class="btn-success"
            :loading="loading"
            :on-click-handler="sendPoApproval"
          >
            <template #icon>
              <ThumbUpIcon />
            </template>
            {{ $t("global.decisions.sendForApproval") }}
          </Button>
          <Button
            v-if="invoiceActionRequired"
            id="approve-button"
            size-class="sm"
            class="btn-success"
            :loading="loading"
            :on-click-handler="() => actionHandler('Approved')"
          >
            <template #icon><ThumbUpIcon /></template>
            {{ $t("global.decisions.approve") }}
          </Button>
          <Button
            v-if="!isObjectStatus('RV') || isAllowedRole"
            id="revision-button"
            class="btn-danger"
            :loading="loading"
            size-class="sm"
            :on-click-handler="
              () =>
                actionHandler(isObjectStatus('RV') ? 'Rejected' : 'Revision')
            "
          >
            <template #icon><ExclamationIcon /></template>
            {{ $t("global.decisions.revision") }}
          </Button>
        </div>
        <ConfirmationModal
          :close-modal="() => actionHandler()"
          :show-modal="showReasonSection"
          :reset-data="resetData"
          :invoice-po-id="invoicePurchaseOrder?.id"
          :button-action="buttonAction"
          :invoice="invoice"
          :order="purchaseRequest?.externalId || ''"
          :is-accountant="isAllowedRole"
        />
      </div>
      <div class="flex justify-center gap-x-2">
        <dl class="dates-holder">
          <div class="cell">
            <dt class="label">{{ $t("global.dates.invoiceDate") }}</dt>
            <dd class="date-value">
              {{ formattedDate(invoice?.invoiceDate) }}
            </dd>
          </div>
          <div class="cell">
            <dt class="label">{{ $t("global.dates.serviceDate") }}</dt>
            <dd class="date-value">
              {{ formattedDate(invoice?.serviceDate) }}
            </dd>
          </div>
          <div class="cell">
            <dt class="label">{{ $t("global.dates.dueDate") }}</dt>
            <dd class="date-value">
              {{ invoice?.dueDate ? formattedDate(invoice?.dueDate) : "-" }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>

  <!-- Start Preview -->
  <div class="flex mt-4 rounded-md border border-gray-200">
    <div class="col !rounded-l-md">
      <ComparisonTableDetails
        :invoice="invoice"
        :selected-ipo="invoicePurchaseOrder?.id"
      />
      <div v-if="invoice.notes" class="notes">
        <p class="label">{{ $t("global.notes") }}</p>
        <div class="notes-box hover:line-clamp-none">
          {{ invoice.notes }}
        </div>
      </div>

      <div
        v-if="!isObjectEmpty(invoicePurchaseOrder)"
        class="flex justify-center w-full"
      >
        <dl class="w-full px-4">
          <div
            class="relative bg-white p-4 border border-gray-200 rounded flex gap-4"
          >
            <dt>
              <div
                class="rounded p-3"
                :class="
                  differenceIndicator(
                    invoicePurchaseOrder?.totalNetAmount,
                    invoicePurchaseOrder?.purchaseOrder?.netAmount,
                    0.05
                  ).bgColor
                "
              >
                <SwitchVerticalIcon
                  class="h-6 w-6"
                  :class="
                    differenceIndicator(
                      invoicePurchaseOrder?.totalNetAmount,
                      invoicePurchaseOrder?.purchaseOrder?.netAmount,
                      0.05
                    ).textColor
                  "
                  aria-hidden="true"
                />
              </div>
            </dt>
            <dd class="flex flex-col">
              <p class="text-sm font-medium text-gray-500">
                <HivebuyTooltip>
                  <span class="cursor-help"
                    >{{ $t("invoice.deviation.base") }} (?)</span
                  >
                  <template #content>
                    <div
                      class="p-3 font-normal text-sm flex flex-col space-y-2 md:w-96"
                    >
                      Deviation is calculated in this way:
                      <div class="py-2">
                        <img
                          :src="'https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/deviation_eq.svg'"
                        />
                      </div>
                      <p>Expected = Purchase Order Amount (net)</p>
                      <p>Actual = Invoice Amount (net)</p>
                    </div>
                  </template>
                </HivebuyTooltip>
              </p>
              <p class="text-xl font-semibold text-gray-900 -ml-2">
                <DifferenceIndicator
                  :actual-amount="invoicePurchaseOrder?.totalNetAmount"
                  :expected-amount="
                    invoicePurchaseOrder?.purchaseOrder.netAmount
                  "
                  :show-total="true"
                />
              </p>
            </dd>
          </div>
        </dl>
      </div>
      <div v-if="!isObjectEmpty(invoiceDetailsObject)" class="px-4 my-4">
        <div class="custom-card no-shadow">
          <span v-if="invoiceDetailsObject.status == 'O'"
            >Invoice is in
            <StatusTag :status="invoiceDetailsObject.status" type="invoice" />
            state and not sent for approval
          </span>
          <Timeline v-else :object="invoiceDetailsObject" :is-invoice="true" />
        </div>
      </div>
    </div>
    <div class="col !rounded-r-md col-left">
      <InvoicePreview
        v-if="invoice.files"
        :invoice="invoice"
        class="rounded-r-md"
      />
    </div>
  </div>
  <StatusUpdateModal
    v-if="showForwardModal"
    :show-modal="showForwardModal"
    :close-modal="toggleForwardModal"
    :requested-purchase-request-status="'delegated'"
    :purchase-request="{ title: invoice.title }"
    :save-action="onConfirmForward"
    :is-loading="isApiLoading('forwardInvoice')"
    :is-invoice="true"
  />
</template>

<script>
import {
  formattedTime,
  isObjectEmpty,
  currencyToLocale,
} from "@/utils/utility_methods";
import { Category, SupplierName } from "@/components/shared";
import {
  ThumbUpIcon,
  SwitchVerticalIcon,
  ExclamationIcon,
  FastForwardIcon,
} from "@heroicons/vue/outline";
import {
  DifferenceIndicator,
  HivebuyTooltip,
  Button,
} from "@/components/shared/index";
import {
  InvoicePreview,
  ConfirmationModal,
  ComparisonTableDetails,
} from "@/components/InvoiceApproval/index.js";

import { differenceIndicator } from "@/utils/utility_methods";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapActions, mapState } from "vuex";
import { Timeline } from "@/components/PurchaseRequest";
import { TimelineMixin } from "@/components/mixins/index.js";
import StatusTag from "@/components/shared/StatusTag";
import markPoPendingMixin from "@/components/mixins/markPoPendingMixin.js";
import Navigation from "@/components/InvoiceApproval/navigation.vue";
import StatusUpdateModal from "@/components/PurchaseRequest/SummarySections/StatusUpdateModal.vue";
import { APPROVAL_STATUS_MAPPING } from "@/utils/constants";
import { INVOICE_MODULE, UPDATE_INVOICE_RELATION } from "@/store/Invoice/types";

export default {
  components: {
    StatusUpdateModal,
    StatusTag,
    InvoicePreview,
    DifferenceIndicator,
    Button,
    ThumbUpIcon,
    HivebuyTooltip,
    SwitchVerticalIcon,
    ExclamationIcon,
    ConfirmationModal,
    Timeline,
    Category,
    SupplierName,
    ComparisonTableDetails,
    Navigation,
    FastForwardIcon,
  },
  mixins: [TimelineMixin, markPoPendingMixin],
  props: {
    invoice: { type: Object, required: true },
    invoicePurchaseOrder: { type: Object, default: () => {} },
    purchaseOrder: { type: Object, default: () => {} },
    purchaseRequest: { type: Object, default: () => {} },
    updateStatus: {
      type: Function,
      default: () => {},
    },
    selectPo: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showReasonSection: false,
      buttonAction: "",
      loading: false,
      showForwardModal: false,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    invoiceDetailsObject() {
      if (this.invoice?.isInvoiceLevelApprover) {
        return this.invoice;
      }
      return this.invoicePurchaseOrder;
    },
    isAllowedRole() {
      return (
        (this.user.roles.includes("AC") || this.user.roles.includes("O")) &&
        ["RV", "P"].includes(this.invoiceDetailsObject?.status)
      );
    },
    invoiceActionRequired() {
      return (
        (this.invoicePurchaseOrder?.status == "P" &&
          this.invoicePurchaseOrder?.actionRequired) ||
        (this.invoice?.status == "P" && this.invoice?.actionRequired)
      );
    },
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      updateRelation: UPDATE_INVOICE_RELATION,
    }),
    formattedTime,
    differenceIndicator,
    currencyToLocale,
    isObjectEmpty,
    async sendPoApproval() {
      const ids = this.invoice.isInvoiceLevelApprover
        ? this.invoice.id
        : [this.invoicePurchaseOrder?.id];
      const payload = {
        ids,
        isInvoiceLevelApprover: this.invoice.isInvoiceLevelApprover,
      };
      const response = await this.markPending(payload);
      this.updateStatus({ invoicePurchaseOrder: response.data[0] });
    },
    isObjectStatus(requiredStatus) {
      return [this.invoice?.status, this.invoicePurchaseOrder?.status]
        .filter((status) => status)
        .includes(requiredStatus);
    },
    resetData(result) {
      this.buttonAction = "";
      this.showReasonSection = false;
      this.invoiceActionRequired = false;
      this.updateStatus(result.data);
    },
    actionHandler(action = "") {
      this.showReasonSection = !this.showReasonSection;
      if (action === "") {
        setTimeout(() => {
          this.buttonAction = action;
        }, "250");
      } else {
        this.buttonAction = action;
      }
    },
    toggleForwardModal() {
      this.showForwardModal = !this.showForwardModal;
    },
    onConfirmForward(decisionNote, forwardedUser) {
      this.setApiLoading({ key: "forwardInvoice", value: true });
      const payload = {
        status: APPROVAL_STATUS_MAPPING.DELEGATED,
        delegatedUser: forwardedUser,
        decisionNote,
      };
      this.updateRelation({
        invoiceRelationId: this.invoice.isInvoiceLevelApprover
          ? this.invoice.id
          : this.invoicePurchaseOrder?.id,
        isInvoiceLevelApprover: this.invoice.isInvoiceLevelApprover,
        payload,
      })
        .then(() => {
          this.showNotification(this.$t("invoice.confirmation.statusUpdated"));
          this.toggleForwardModal();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() =>
          this.setApiLoading({ key: "forwardInvoice", value: false })
        );
    },
  },
};
</script>

<style scoped>
.header {
  @apply flex justify-between border-gray-200 items-center gap-x-2 md:gap-x-4 lg:gap-x-8;
}

.header-left {
  @apply text-sm font-medium flex-1 w-1/2;
  min-width: 0;
}

.header-right {
  @apply flex-initial;
}

.col {
  @apply w-1/2 flex-1 rounded-none border-0;
}

.col:first-child {
  border-right: 0;
}

.buttons-holder {
  @apply flex gap-x-2 mb-2;
}

.dates-holder {
  @apply grid grid-cols-1 rounded bg-white overflow-hidden border border-gray-200 divide-y divide-gray-200 md:grid-cols-3 md:gap-x-4 md:divide-y-0 md:divide-x;
}

.cell {
  @apply p-1.5 flex gap-x-1 items-center;
}

.cell .label {
  @apply mb-0 text-sm;
}
.cell .date-value {
  @apply text-primary font-semibold text-xs;
}

.notes {
  @apply p-4 border-t border-gray-200;
}

.notes-box {
  @apply border border-yellow-200 rounded flex p-4 text-base bg-yellow-50 line-clamp-4;
  overflow-wrap: anywhere;
}

.col-left {
  @apply border-l border-gray-200 border-b -mb-[1px];
  height: calc(100vh - 280px);
}
</style>
