<template>
  <div class="flex flex-col">
    <div class="custom-card overflow-visible">
      <div class="-m-5">
        <table class="table">
          <thead class="table-header">
            <tr>
              <th scope="col">
                <div class="flex items-center">
                  <span class="pr-2">
                    {{ $t("companyManagement.users.table.headerName") }}
                  </span>
                  <span
                    :class="getSortClass()"
                    @click="() => setSortValues('first_name')"
                  />
                </div>
              </th>
              <th scope="col">
                {{ $t("companyManagement.users.table.headerDepartment") }}
              </th>
              <th scope="col">
                {{ $t("companyManagement.users.table.headerStatus") }}
              </th>
              <th scope="col">
                <div class="flex">
                  <span class="translate-y-1">
                    {{ $t("companyManagement.users.table.headerRoles") }}</span
                  >
                  <UserRoles :display-all-roles="true" :info-tooltip="true" />
                </div>
              </th>
              <th v-if="isFeatureAllowed('legalEntity')" scope="col">
                {{ $t("companyManagement.users.table.legalEntity") }}
              </th>
              <th scope="col" class="relative">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr>
              <td colspan="5" align="center" class="search-holder">
                <div
                  class="left-0 w-[calc(100vw-70px)] sm:w-[calc(100vw-370px)] lg:w-[calc(100vw-390px)] 2xl:w-[calc(100vw-425px)] z-[999]"
                >
                  <SearchInput
                    :value="paginationParams.search"
                    :on-change="updatePaginationParams"
                    :disabled="isLoading"
                    :placeholder="$t('companyManagement.users.search')"
                  />
                </div>
              </td>
            </tr>
            <TableRowsLoader
              v-if="isLoading"
              :rows="5"
              :columns="isFeatureAllowed('legalEntity') ? 5 : 4"
            />
            <tr v-else-if="!isLoading && !areUsersPresent">
              <td colspan="5" align="center">
                <p class="bg-white px-6 py-4 w-full font-medium text-gray-700">
                  {{ $t("companyManagement.users.noRecords") }}
                </p>
              </td>
            </tr>
            <tr
              v-for="user in userDetails"
              v-else
              :key="user.id"
              :class="[
                'group',
                {
                  'bg-clip-border p-6 border-4 !border-x-hivebuy-red':
                    !user.isActive,
                },
              ]"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                <UserWithAvatar
                  :user="user"
                  :show-email="true"
                  :show-name="true"
                  :show-tooltip="false"
                  :is-active="user.isActive"
                />
              </td>
              <td>
                <div v-if="user.departments?.length" class="flex">
                  <EntityList
                    :list="user.departments"
                    :entity-type="'department'"
                    :show-delete="false"
                    :show-tooltip="true"
                    :avatar-dimension="7"
                  />
                </div>
                <p v-else class="text-gray-500 text-sm text-left">N/A</p>
              </td>
              <td>
                <div class="flex h-full items-center">
                  <div
                    class="w-8 h-8 flex items-center justify-between"
                    :class="
                      isStatusActive(user)
                        ? 'text-hivebuy-green'
                        : 'text-hivebuy-red'
                    "
                  >
                    <CheckCircleIcon
                      v-if="isStatusActive(user)"
                      class="h-5 w-5"
                    />
                    <BanIcon v-else class="h-5 w-5" />
                  </div>
                </div>
              </td>
              <td>
                <div class="flex items-center mb-2 text-white">
                  <UserRoles :user-roles="user.roles" />
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                {{
                  user.legalEntity
                    ? legalTendencyDetails(user.legalEntity)
                    : "-"
                }}
              </td>
              <td v-if="!isUserOnlyAuditor" class="w-4">
                <div
                  class="text-gray-300 flex items-center gap-x-2 justify-end"
                >
                  <div
                    v-if="user.isActive"
                    class="px-2 py-2 rounded-full cursor-pointer flex items-center justify-center hover:bg-gray-200 group hover:text-hivebuy-green"
                    @click="onEdit(user)"
                  >
                    <PencilAltIcon class="w-5 h-5" />
                  </div>
                  <div
                    v-if="user.isActive"
                    class="px-2 py-2 rounded-full cursor-pointer flex items-center justify-center hover:bg-gray-200 group hover:text-hivebuy-green"
                    @click="() => showHistory(user)"
                  >
                    <HistorySVG class="w-5 h-5" />
                  </div>
                  <div
                    v-if="!isCurrentUser(user)"
                    class="px-2 py-2 rounded-full cursor-pointer hover:bg-gray-200"
                    :class="[
                      `hover:text-hivebuy-${userStatusHoverColor(user)}`,
                    ]"
                    @click="calcUpdateStatusUser(user)"
                  >
                    <FlagIcon class="w-5 h-5" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="custom-pagination mt-4">
        <v-pagination
          v-if="areUsersPresent"
          v-model="paginationParams.page"
          :pages="parseInt(paginationParams.totalPages)"
          :range-size="1"
          @update:model-value="(val) => updatePaginationParams('page', val)"
        />
      </div>
      <div v-if="!isLoading" class="flex justify-center text-sm">
        <div>
          <i18n-t keypath="companyManagement.users.footer">
            <template #number>
              <span class="mx-1 text-primary font-medium">{{
                paginationParams.totalRecords
              }}</span>
            </template>
            <template #user>
              <span v-if="active">{{
                $t("companyManagement.users.activeUsers")
              }}</span>
              <span v-else>{{
                $t("companyManagement.users.inactiveUsers")
              }}</span>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
    <EditUserModal
      :user-to-edit="userToEdit"
      :show-modal="showEditUserModal"
      :close-modal="onEditUserModalClose"
      :fetch-users="fetchUsers"
    />
    <ConfirmDialog
      text="Are you sure you want to delete the following user?"
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteModalClose"
      title="Delete User"
      :item-to-confirm="fullName(userToDelete)"
      :confirm-modal="onDeleteUser"
    />
    <ConfirmDialog
      :text="updateStatusUser.text"
      :show-modal="showStatusUpdateConfirmationModal"
      :close-modal="onStatusUpdateModalClose"
      :title="updateStatusUser.title"
      :item-to-confirm="fullName(userToUpdate)"
      :confirm-modal="changeUserStatus"
      :success="updateStatusUser.success"
    />
    <HistoryModal
      type="user"
      :show-modal="showHistoryModal"
      :on-close-modal="closeHistory"
      :object="historyObject"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import HistorySVG from "@/assets/images/HistorySVG";

import {
  COMPANY_MANAGEMENT_MODULE,
  DELETE_USER,
  GET_USERS_LIST,
  CHANGE_USER_STATUS,
} from "@/store/CompanyManagement/types";

import {
  userMixin,
  sortingMixin,
  debounceMixin,
  paginationMixin,
} from "@/components/mixins/index.js";
import { PencilAltIcon, FlagIcon } from "@heroicons/vue/solid";
import EditUserModal from "@/components/CompanyManagement/Users/EditUserModal";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import HistoryModal from "@/components/shared/HistoryModal.vue";
import {
  UserWithAvatar,
  ConfirmDialog,
  TableRowsLoader,
  UserRoles,
  EntityList,
} from "@/components/shared/index";
import { USER_ROLES_MAPPING, USER_ROLES_HELP } from "@/utils/constants";
import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInput";
import { CheckCircleIcon, BanIcon } from "@heroicons/vue/solid";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  name: "UsersList",
  components: {
    UserRoles,
    PencilAltIcon,
    ConfirmDialog,
    EditUserModal,
    VPagination,
    UserWithAvatar,
    TableRowsLoader,
    SearchInput,
    CheckCircleIcon,
    BanIcon,
    FlagIcon,
    EntityList,
    HistorySVG,
    HistoryModal,
  },
  mixins: [userMixin, sortingMixin, debounceMixin, paginationMixin],
  props: {
    search: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentUsers: [],
      isLoading: false,
      showDeleteConfirmModal: false,
      showStatusUpdateConfirmationModal: false,
      showEditUserModal: false,
      userToDelete: {},
      userToUpdate: {},
      userToEdit: {},
      rolesForInformationPopover: USER_ROLES_MAPPING,
      rolesHelpText: USER_ROLES_HELP,
      areFiltersUpdated: false,
      updateStatusUser: {
        text: "",
        title: "",
      },
      showHistoryModal: false,
      historyObject: {},
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["users"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyDetails"]),
    areUsersPresent() {
      return this.currentUsers?.length;
    },
    userDetails() {
      return this.currentUsers.map((user) => this.users[user.id] || user);
    },
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.executeDebounceSearch(this.updatePaginationParams, {
            key: "search",
            value: newVal,
          });
        }
      },
    },
    active: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.paginationParams.active = this.active;
          this.fetchUsers();
        }
      },
    },
  },
  created() {
    this.paginationParams.pageSize = 20;
    this.paginationParams.active = this.active;
    this.fetchUsers();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUsersList: GET_USERS_LIST,
      deleteUser: DELETE_USER,
      changeStatus: CHANGE_USER_STATUS,
    }),
    isCurrentUser(user) {
      return this.user.id == user.id;
    },
    changeUserStatus() {
      if (this.isCurrentUser(this.userToUpdate)) return;

      const payload = {
        userId: this.userToUpdate.id,
        status: !this.userToUpdate.isActive,
      };
      this.changeStatus(payload)
        .then(() => {
          this.showNotification("User status is changed successfully");
          const userIndex = this.currentUsers.findIndex(
            (user) => user.id == payload.userId
          );
          this.currentUsers[userIndex].isActive = payload.status;
        })
        .finally(() => {
          this.userToUpdate = {};
          this.showStatusUpdateConfirmationModal = false;
        });
    },

    userStatusHoverColor(user) {
      return user.isActive ? "red" : "green";
    },
    fetchUsers() {
      this.isLoading = true;

      if (this.areFiltersUpdated) {
        this.paginationParams.page = 1;
      }

      this.getUsersList(this.paginationParams)
        .then((response) => {
          const {
            currentPage: page,
            totalPages,
            totalRecords,
            results,
          } = response;
          this.currentUsers = results;
          this.paginationParams = {
            ...this.paginationParams,
            ...{ page, totalPages, totalRecords },
          };
          this.areFiltersUpdated = false;
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => (this.isLoading = false));
    },
    status(user) {
      return this.isStatusActive(user) ? "Active" : "Pending";
    },
    isStatusActive(user) {
      return user.isActive;
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    onStatusUpdateModalClose() {
      this.showStatusUpdateConfirmationModal = false;
      this.userToUpdate = {};
    },
    confirmDeleteUser(user) {
      this.userToDelete = user;
      this.showDeleteConfirmModal = true;
    },
    onDeleteUser() {
      this.showDeleteConfirmModal = false;
      this.deleteUser(this.userToDelete.id)
        .then(() => {
          this.fetchUsers();
          this.showNotification("User has been deleted");
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onEdit(user) {
      this.userToEdit = user;
      this.showEditUserModal = true;
    },
    onEditUserModalClose() {
      this.userToEdit = {};
      this.showEditUserModal = false;
    },
    updatePaginationParams(key, value) {
      if (key !== "page") {
        this.areFiltersUpdated = true;
      }

      this.paginationParams[key] = value;
      this.fetchUsers();
    },
    calcUpdateStatusUser(user) {
      if (user.isActive) {
        this.updateStatusUser.text =
          "Are you sure you want to deactivate the following account?";
        this.updateStatusUser.success = false;
        this.updateStatusUser.title = "Deactivate User";
      } else {
        this.updateStatusUser.text =
          "Are you sure you want to restore the following account?";
        this.updateStatusUser.success = true;
        this.updateStatusUser.title = "Restore User";
      }
      this.userToUpdate = user;
      this.showStatusUpdateConfirmationModal = true;
    },
    showHistory(user) {
      let name = user.email;
      if (user.firstName && user.lastName) {
        name = user.firstName + " " + user.lastName;
      }

      this.historyObject = {
        id: user.id,
        name: name,
      };

      this.showHistoryModal = true;
    },

    closeHistory() {
      this.showHistoryModal = false;
      setTimeout(() => {
        this.historyObject = {};
      }, 300);
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles/sorting.css";

.icons-holder {
  @apply flex justify-between items-center;
}
</style>

<style lang="scss" scoped>
@import "../../../assets/styles/pagination.scss";
</style>
