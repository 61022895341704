<template>
  <Multiselect
    v-model="computedValue"
    :can-clear="canClear"
    :clear-on-select="clearOnSelect"
    :filter-results="filterResults"
    :loading="loading"
    :options="options"
    :placeholder="placeholder"
    :searchable="searchable"
    :show-options="showOptions"
    :track-by="trackBy"
    :value-prop="valueProp"
    :label="label"
    :object="object"
    class="w-full bg-white"
    :mode="mode"
    :name="name"
    :hide-selected="hideSelected"
    :create-option="createOption"
    :on-create="onCreate"
    @search-change="$emit('search-change', $event)"
    @open="enableLazyLoading ? $emit('open') : null"
    @close="enableLazyLoading ? $emit('close') : null"
  >
    <template #afterlist>
      <slot name="createOption" />
      <div
        v-if="enableLazyLoading && loadMoreRecordsLoading"
        class="flex justify-center"
      >
        <HivebuyLoader class="h-8 w-8 p-1 m-1" />
      </div>
    </template>
    <template #option="{ option }">
      <slot name="option" :option="option" />
    </template>

    <template #nooptions>
      <slot name="nooptions" />
    </template>

    <template #singlelabel="{ value }">
      <slot name="singlelabel" :value="value" />
    </template>
  </Multiselect>
</template>

<script>
import { HivebuyLoader } from "@/components/shared/index";
import Multiselect from "@vueform/multiselect";

export default {
  name: "MultiselectWithLazyLoading",
  components: {
    Multiselect,
    HivebuyLoader,
  },
  props: {
    modelValue: {
      type: [String, Object, Array],
      required: true,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    filterResults: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: "id",
    },
    valueProp: {
      type: String,
      default: "id",
    },
    mode: {
      type: String,
      default: "single",
    },
    name: {
      type: String,
      default: "",
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "label",
    },
    object: {
      type: Boolean,
      default: false,
    },
    enableLazyLoading: {
      type: Boolean,
      default: true,
    },
    loadMoreRecordsLoading: {
      type: Boolean,
      default: false,
    },
    createOption: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: () => {},
    },
  },
  emits: ["update:modelValue", "open", "close", "search-change"],
  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
