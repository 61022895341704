import { deleteObject, post, patch, get, cachedGet } from "../api";
import {
  USERS_API_URL,
  INVITE_API_URL,
  USERS_AUTH_API_URL,
  RESEND_INVITE_URL,
  SIGN_UP,
  HUBSPOT_URL,
} from "@/utils/services/apiUrls.js";
import { formattedURL } from "@/utils/utility_methods";

import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";
import { UPDATE_CURRENT_USER } from "@/store/Auth/types";

export const signUp = (payload, resolve, reject) => {
  post(SIGN_UP, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getUsersList = (resolve, reject, paginationParams) => {
  get(formattedURL(USERS_API_URL, paginationParams))
    .then((response) => resolve(response.data))
    .catch((err) => reject(err));
};

export const getUsers = (commit, resolve, reject, paginationParams) => {
  cachedGet(formattedURL(USERS_API_URL, paginationParams))
    .then((response) => {
      const results = response.data?.results || response.data;
      commit(CompanyManagementTypes.SET_USERS, results);
      resolve(results);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteUser = (userId, resolve, reject) => {
  if (!userId) return;

  deleteObject(`${USERS_API_URL}${userId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getUser = (userId, resolve, reject) => {
  if (!userId) return;

  get(`${USERS_API_URL}${userId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const inviteUser = (payload, resolve, reject) => {
  post(INVITE_API_URL, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const resendInvite = (payload, resolve, reject) => {
  post(`${RESEND_INVITE_URL}${payload}/resend/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateUser = (userId, payload, commit, resolve, reject) => {
  if (!userId) return;

  patch(`${USERS_API_URL}${userId}/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_USER, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateMe = (commit, payload, resolve, reject) => {
  patch(`${USERS_AUTH_API_URL}me/`, payload)
    .then((response) => {
      commit(UPDATE_CURRENT_USER, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPendingUsers = (commit, resolve, reject) => {
  get(INVITE_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_PENDING_USERS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deletePendingUser = (userId, resolve, reject) => {
  if (!userId) return;

  deleteObject(`${INVITE_API_URL}${userId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const changeUserStatus = (userId, status, commit, resolve, reject) => {
  if (!userId) return;

  patch(`${USERS_API_URL}${userId}/`, status)
    .then((response) => {
      commit(CompanyManagementTypes.SET_USER_STATUS, { userId, status });
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getHubspotToken = (resolve, reject) => {
  post(HUBSPOT_URL)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
