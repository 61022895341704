import { listPageFiltersInitialValue } from "@/utils/helpers/purchaseRequestHelper";

export default {
  openCurrentRequest: {},
  purchaseRequestDetails: {},
  itemDetails: {},
  currentRequest: {
    title: "",
    category: null,
    supplier: null,
    shoppingLists: [],
    items: [],
    itemLevelApproval: false,
    currentPage: "category",
    editItemPosition: null,
  },
  newRequest: {
    title: null,
    titleChanged: false,
    category: null,
    supplier: null,
    items: [],
    itemLevelApproval: false,
  },

  listFilters: {
    paginationParams: {
      page: 1,
      pageSize: 10,
      totalPages: "",
      totalRecords: "",
      search: "",
    },
    advancedFilters: listPageFiltersInitialValue(),
  },
  list: [],
  purchaseRequests: [],
  recentlyViewed: [],
  draftListParams: {},
  draftPurchaseRequests: [],
  requestedForValues: {},
};
